<template>
  <surroundBox :name="description" :unit="unit" desc="加工秸秆重量">
    <template slot="content">
      <div class="straw-process-echart" style="width:100%;height:100%"></div>
    </template>
  </surroundBox>
</template>

<script>
import * as echarts from 'echarts'
import surroundBox from "../surroundBox/surroundBox";

export default {
  name: "straw",
  components: {
    surroundBox,
  },
  data() {
    return {
      description: "秸秆加工",
      chart: null,
      unit: "万吨",
      datas: [],
    }
  },
  mounted() {
    this.loadData().then(() => {
      this.initCharts();
    });
    window.addEventListener('resize', () => {
      this.chart && this.chart.resize()
    })
  },
  methods: {
    initCharts() {
      if (this.chart == null) {
        this.chart = echarts.init(document.querySelector(".straw-process-echart"))
      }
      let option = {
        roam: false,
        tooltip: {
          show: false,
        },
        grid: {
          containLabel: true,
          top: 38,
          left: 25,
          right: 32,
          bottom: 18,
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 0, //0度角倾斜显示
              show: true,
              fontSize: 12,
              color: "#DAE1EB",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#586F91",
              },
            },
            axisTick: {
              show: false,
            },
            offset: 8,
            data: this.datas.map((item) => item.time),
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#DAE1EB",
              fontSize: 12,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ["#586F91"],
                type: "solid",
              },
            },
            axisTick:{
              show: false,
            },
            // offset: 6,
          },
        ],

        series: [
          {
            name: "a",
            tooltip: {
              show: false,
            },
            data: this.datas.map((item) => item.val),
            hoverAnimation: false,
            type: "bar",
            barWidth: 9,
            // barGap: 200,
            // barCategoryGap: 200,

            label: {
              show: true, // 柱子顶部的数值
              position: "top",
              top: 0,
              fontSize: 12,
              color: "#C3D8F3",
              offset: [4, 0],
              formatter: function(params) {
                let num = params.value;
                if (num * 1 === 0) return "";
                return num;
              },
            },
            itemStyle: {
              normal: {
                color: "#1382D8",
              },
            },

            // barGap: 0
          },
          {
            type: "bar",
            barWidth: 9,
            itemStyle: {
              normal: {
                color: "#50B0DA",
              },
            },
            hoverAnimation: false,
            barGap: 0,
            data: this.datas.map((item) => item.val),
          },
          {
            name: "b",
            tooltip: {
              show: false,
            },
            type: "pictorialBar",
            itemStyle: {
              borderWidth: 0,
              borderColor: "#50B0DA",
              color: "#359CEC",
            },
            symbol: "diamond",
            symbolSize: ["18", "12"],
            symbolOffset: ["0", "-6"], // 左右 ，上下
            symbolRotate: 0,
            symbolPosition: "end",
            data: this.datas.map((item) => item.val),
            z: 3,
          },
        ],
      }
      this.chart.setOption(option)
    },
    loadData() {
      this.datas = []
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO,{
        resourceTypeId: 166
      }).then(res => {
        if (res && res.length > 0) {
          res.forEach((item) => {
            this.datas.push({
              time: item.year,
              val: +item.content,
            })
          })
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>
